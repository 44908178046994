import React from "react"
import { Helmet } from "react-helmet"

import Layout from "../../components/Layout"
import * as styles from "../../styles/project-details.module.css"
import { graphql } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default function ProjectDetails({ data }) {
  const { html } = data.markdownRemark

  const { title, stack, url, github } = data.markdownRemark.frontmatter

  const image = getImage(
    data.markdownRemark.frontmatter.mockup.childImageSharp.gatsbyImageData
  )

  return (
    <Layout>
      <Helmet>
        <meta
          name="description"
          content={`Detailed information to Patrick Oldman's website '${title}'.`}
        />
        <title>Patrick Oldman | {title}</title>
      </Helmet>
      <div className={styles.details}>
        <div className={styles.header}>
          <h2>{title}</h2>
          <h3>{stack}</h3>
        </div>
        <div className={styles.btn}>
          <a href={url} target="_blank" rel="noreferrer">
            Live site <FontAwesomeIcon icon="external-link-alt" />
          </a>
          {github && (
            <a href={github} target="_blank" rel="noreferrer">
              GitHub <FontAwesomeIcon icon={["fab", "github"]} />
            </a>
          )}
        </div>
        <a
          className={styles.featured}
          href={url}
          target="_blank"
          rel="noreferrer"
        >
          <GatsbyImage image={image} alt="Picture" />
        </a>
        <div
          className={styles.html}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ProjectDetails($id: String) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        stack
        url
        github
        mockup {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`
